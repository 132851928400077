define("discourse/plugins/fee-calculator/discourse/controllers/fees", ["exports", "discourse-common/utils/decorators", "@ember/string", "@ember/utils", "@ember/runloop", "@ember/service", "@ember/controller"], function (_exports, _decorators, _string, _utils, _runloop, _service, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    dialog: (0, _service.inject)(),
    price: null,
    category: null,
    paymentType: null,
    membershipType: null,
    categories: (0, _string.w)("social_media domains websites shoutout trades auction other"),
    paymentTypes: (0, _string.w)("bank_wire trc20 erc20 trustap_bank_wire trustap_creditcard"),
    membershipTypes: (0, _string.w)("safety_concern regular premium vip diamond millionaire partner"),
    showResult: false,
    showTransferFee: true,
    actions: {
      calculateFee() {
        if (!this.get("canCalculateFee")) {
          return;
        }
        if (this._isShowLimit()) {
          return;
        }
        this.set("showResult", true);
        if (this.site.mobileView) {
          (0, _runloop.next)(this, () => {
            $("html, body").animate({
              scrollTop: $(".fc-right").offset().top - 75
            }, 2000);
          });
        }
      }
    },
    roundUpToCents(amount) {
      let roundedAmount = Math.ceil(amount * 100) / 100;
      return roundedAmount.toFixed(2);
    },
    canCalculateFee(price, category, paymentType, membershipType) {
      if (category == "trades") return true;
      if ((0, _utils.isBlank)(price)) return false;
      if (isNaN(price)) return false;
      if (parseFloat(price) < 0) return false;
      if (price > 9999999) return false;
      if (!this.get("categories").includes(category)) return false;
      if (!this.get("paymentTypes").includes(paymentType)) return false;
      if (!this.get("membershipTypes").includes(membershipType)) return false;
      return true;
    },
    categoriesContent(categories) {
      return categories.map(c => {
        return {
          id: c,
          name: I18n.t(`fee_calculator.categories.${c}`)
        };
      });
    },
    paymentTypesContent(paymentTypes) {
      return paymentTypes.map(c => {
        return {
          id: c,
          name: I18n.t(`fee_calculator.payment_types.${c}`)
        };
      });
    },
    membershipTypesContent(membershipTypes) {
      return membershipTypes.map(c => {
        return {
          id: c,
          name: I18n.t(`fee_calculator.membership_type.${c}`)
        };
      });
    },
    cantCalculateFee(canCalculateFee) {
      return !canCalculateFee;
    },
    formatedPrice(price) {
      return parseFloat(price);
    },
    _hideResult() {
      this.set("showResult", false);
    },
    _calculateActualFee(feeStruct, price) {
      return {
        percentage: feeStruct.percentage,
        fixedFee: feeStruct.fixedFee,
        validUpto: feeStruct.validUpto,
        actualFee: feeStruct.fixedFee + feeStruct.percentage * price / 100
      };
    },
    _calculateSwapdFee(price, category, membershipType, paymentType) {
      const applyMinimum = (feeStruct, price, minimum) => {
        const fee = price * feeStruct.percentage / 100 + feeStruct.fixedFee;
        if (fee < minimum) {
          if (feeStruct.percentage == 0) {
            // if fixed fee then keep validUpto value
            return {
              percentage: 0,
              fixedFee: minimum,
              validUpto: feeStruct.validUpto
            };
          } else {
            return {
              percentage: 0,
              fixedFee: minimum,
              validUpto: 100 * minimum / feeStruct.percentage
            };
          }
        } else {
          return feeStruct;
        }
      };
      const getFee = (price, percentage, minimum) => {
        return this._calculateActualFee(applyMinimum({
          percentage: percentage,
          fixedFee: 0,
          validUpto: 99999999
        }, price, minimum), price);
      };
      function getApplicablePercentage(percentages, price, bottom) {
        let applicablePercentage = bottom;
        for (const percentage of percentages) {
          if (price <= percentage.price) {
            applicablePercentage = {
              percentage: percentage.percentage,
              fixedFee: 0,
              validUpto: percentage.price
            };
            break;
          }
        }
        return applicablePercentage;
      }

      // Trustap is independent of category
      if (['trustap_bank_wire', 'trustap_creditcard'].includes(paymentType)) {
        if (price < 200) {
          return {
            percentage: 0,
            fixedFee: 20,
            validUpto: 200,
            actualFee: 20
          };
        } else {
          return {
            percentage: 7,
            fixedFee: 0,
            validUpto: 99999999
          };
        }
      }
      var feeStruct = {};
      switch (category) {
        case 'social_media':
        case 'other':
          if (price < 200) {
            return {
              percentage: 0,
              fixedFee: 20,
              validUpto: 200,
              actualFee: 20
            };
          }
          switch (paymentType) {
            case 'usdc':
            case 'usdt':
            case 'trc20':
            case 'erc20':
              feeStruct = {
                percentage: 10,
                fixedFee: 0,
                validUpto: 99999999
              };
              switch (membershipType) {
                case 'safety_concern':
                  feeStruct = {
                    percentage: 10,
                    fixedFee: 0,
                    validUpto: 99999999
                  };
                  break;
                case 'regular':
                case 'premium':
                  feeStruct = {
                    percentage: 9,
                    fixedFee: 0,
                    validUpto: 99999999
                  };
                  break;
                case 'vip':
                  feeStruct = getApplicablePercentage([{
                    price: 20000,
                    percentage: 8
                  }, {
                    price: 40000,
                    percentage: 7
                  }, {
                    price: 60000,
                    percentage: 6
                  }, {
                    price: 10000000,
                    percentage: 5
                  }], price, 4);
                  break;
                case 'diamond':
                  feeStruct = getApplicablePercentage([{
                    price: 20000,
                    percentage: 7
                  }, {
                    price: 40000,
                    percentage: 6
                  }, {
                    price: 60000,
                    percentage: 5
                  }, {
                    price: 10000000,
                    percentage: 4
                  }], price, 3);
                  break;
                case 'millionaire':
                case 'partner':
                  feeStruct = getApplicablePercentage([{
                    price: 20000,
                    percentage: 6
                  }, {
                    price: 40000,
                    percentage: 5
                  }, {
                    price: 60000,
                    percentage: 4
                  }, {
                    price: 10000000,
                    percentage: 3
                  }], price, 2);
                  break;
              }
              break;
            case 'creditcard':
              var percentages = {
                safety_concern: 100,
                regular: 100,
                premium: 100,
                vip: 12,
                diamond: 12,
                millionaire: 12,
                partner: 10
              };
              feeStruct = {
                percentage: percentages[membershipType],
                fixedFee: 0,
                validUpto: 99999999
              };
              break;
            case 'bank_wire':
              var percentages = {
                safety_concern: 11,
                regular: 10,
                premium: 10,
                vip: 9,
                diamond: 8,
                millionaire: 7,
                partner: 6
              };
              feeStruct = {
                percentage: percentages[membershipType],
                fixedFee: 0,
                validUpto: 99999999
              };
              break;
          }
          return this._calculateActualFee(feeStruct, price);
          // see https://support.communiteq.com/t/fee-calculator-issues/319/8
          break;
        case 'auction':
          if (price < 200) {
            return {
              percentage: 0,
              fixedFee: 20,
              validUpto: 200,
              actualFee: 20
            };
          }
          var percentages = {
            safety_concern: 11,
            regular: 10,
            premium: 9,
            vip: 8,
            diamond: 7,
            millionaire: 6,
            partner: 6
          };
          var percent = percentages[membershipType];
          feeStruct = {
            percentage: percent,
            fixedFee: 0,
            validUpto: 99999999
          };
          return this._calculateActualFee(feeStruct, price);
          break;
        case 'domains':
          // 1% and 25 minimum
          return getFee(price, 1, 25);
          break;
        case 'websites':
          // 5% and 50 minimum
          return getFee(price, 5, 50);
          break;
        case 'shoutout':
          // 10% and 20 minimum
          return getFee(price, 10, 20);
        case 'trades':
          // always 35
          return {
            percentage: 0,
            fixedFee: 35,
            validUpto: 99999999,
            actualFee: 35
          };
          break;
      }
    },
    swapdFee(price, category, membershipType, paymentType) {
      const fee = this._calculateSwapdFee(price, category, membershipType, paymentType);
      return this.roundUpToCents(price * fee.percentage / 100 + fee.fixedFee);
    },
    _getTransferFee(price, paymentType, category) {
      var feeStruct = {};

      // trustap is independent of the category
      if (paymentType == "trustap_bank_wire") {
        feeStruct = {
          percentage: 1,
          fixedFee: 0,
          validUpto: 99999999
        };
        return this._calculateActualFee(feeStruct, price);
      }
      if (paymentType == "trustap_creditcard") {
        feeStruct = {
          percentage: 4,
          fixedFee: 0,
          validUpto: 99999999
        };
        return this._calculateActualFee(feeStruct, price);
      }

      // trade category is independent of payment type
      if (category == "trades") {
        feeStruct = {
          percentage: 0,
          fixedFee: 0,
          validUpto: 99999999
        };
        return this._calculateActualFee(feeStruct, price);
      }
      switch (paymentType) {
        case "bank_wire":
          feeStruct = {
            percentage: 0,
            fixedFee: 35,
            validUpto: 99999999
          };
          break;
        case "usdt":
          feeStruct = {
            percentage: 1,
            fixedFee: 0,
            validUpto: 99999999
          };
          break;
        case "usdc":
          feeStruct = {
            percentage: 1,
            fixedFee: 0,
            validUpto: 99999999
          };
          break;
        case "erc20":
          feeStruct = {
            percentage: 1,
            fixedFee: 10,
            validUpto: 99999999
          };
          break;
        case "trc20":
          feeStruct = {
            percentage: 1,
            fixedFee: 5,
            validUpto: 99999999
          };
          break;
        case "credit_card":
        default:
          feeStruct = {
            percentage: 0,
            fixedFee: 0,
            validUpto: 99999999
          };
      }
      return this._calculateActualFee(feeStruct, price);
    },
    transferFee(price, category, membershipType, paymentType) {
      var fee = this._getTransferFee(price, paymentType, category);
      this.set("showTransferFee", fee.actualFee > 0);
      return this.roundUpToCents(fee.actualFee);
    },
    finalAmount(price, swapdFee, transferFee, category) {
      if (category == "trades") return 0;
      return this.roundUpToCents(Math.max(0, this.roundUpToCents(price - swapdFee - transferFee)));
    },
    _isOverLimit(price, paymentType, membershipType, category, popup) {
      if (category == "trades") {
        return false;
      }
      if (paymentType == "bank_wire") {
        if (['partner'].includes(membershipType)) {
          if (price > 20000) {
            const msg = I18n.t("fee_calculator.payment_limit.bank_wire_partner");
            if (popup) {
              this._showLimit(msg);
            }
            return true;
          }
        } else {
          if (price > 10000) {
            const msg = I18n.t("fee_calculator.payment_limit.bank_wire");
            if (popup) {
              this._showLimit(msg);
            }
            return true;
          }
        }
      }

      // trustap restrictions
      if (['trustap_bank_wire', 'trustap_creditcard'].includes(paymentType)) {
        if (['safety_concern'].includes(membershipType)) {
          const msg = I18n.t("fee_calculator.payment_limit.trustap_not_allowed");
          if (popup) {
            this._showLimit(msg);
          }
          return true;
        }
      }
      if (paymentType == "trustap_creditcard" && price > 2247.20) {
        // 2247.20 equals to 2000 plus fees
        const msg = I18n.t("fee_calculator.payment_limit.trustap_creditcard_limit");
        if (popup) {
          this._showLimit(msg);
        }
        return true;
      }

      // credit card limits and restrictions
      if (paymentType == "creditcard") {
        if (['safety_concern', 'regular', 'premium'].includes(membershipType)) {
          const msg = I18n.t("fee_calculator.payment_limit.creditcard_not_allowed");
          if (popup) {
            this._showLimit(msg);
          }
          return true;
        }
        if (['vip'].includes(membershipType) && price > 1000) {
          const msg = I18n.t("fee_calculator.payment_limit.creditcard_vip");
          if (popup) {
            this._showLimit(msg);
          }
          return true;
        }
        if (['diamond', 'millionaire'].includes(membershipType) && price > 2000) {
          const msg = I18n.t("fee_calculator.payment_limit.creditcard_diamond");
          if (popup) {
            this._showLimit(msg);
          }
          return true;
        }
        if (['partner'].includes(membershipType) && price > 2500) {
          const msg = I18n.t("fee_calculator.payment_limit.creditcard_partner");
          if (popup) {
            this._showLimit(msg);
          }
          return true;
        }
      }
      return false;
    },
    exceedsLimitOA(orgAmount, price, category, membershipType, paymentType) {
      if (category == "trades") {
        return true;
      } else {
        return this._isOverLimit(orgAmount, paymentType, membershipType, category, false);
      }
    },
    orgAmount(price, category, membershipType, paymentType) {
      var oa = 0;
      var virtualPrice = price;
      var transferFee, swapdFee;
      do {
        transferFee = this._getTransferFee(virtualPrice, paymentType, category);
        swapdFee = this._calculateSwapdFee(virtualPrice, category, membershipType, paymentType);
        oa = 100 * ((price + transferFee.fixedFee + swapdFee.fixedFee) / (100 - (transferFee.percentage + swapdFee.percentage)));
        var limit = Math.min(transferFee.validUpto, swapdFee.validUpto);
        if (oa > limit) {
          virtualPrice = limit + 1;
        } else {
          break;
        }
      } while (true);
      return this.roundUpToCents(oa > 0 ? oa : 0);
    },
    _isShowLimit() {
      const price = this.get("formatedPrice");
      const paymentType = this.get("paymentType");
      const membershipType = this.get("membershipType");
      const category = this.get("category");
      return this._isOverLimit(price, paymentType, membershipType, category, true);
    },
    _showLimit(msg) {
      this.dialog.alert({
        message: msg
      });
    }
  }, [["method", "canCalculateFee", [(0, _decorators.default)("price", "category", "paymentType", "membershipType")]], ["method", "categoriesContent", [(0, _decorators.default)("categories")]], ["method", "paymentTypesContent", [(0, _decorators.default)("paymentTypes")]], ["method", "membershipTypesContent", [(0, _decorators.default)("membershipTypes")]], ["method", "cantCalculateFee", [(0, _decorators.default)("canCalculateFee")]], ["method", "formatedPrice", [(0, _decorators.default)("price")]], ["method", "_hideResult", [(0, _decorators.observes)("price", "category", "paymentType", "membershipType")]], ["method", "swapdFee", [(0, _decorators.default)("formatedPrice", "category", "membershipType", "paymentType")]], ["method", "transferFee", [(0, _decorators.default)("formatedPrice", "category", "membershipType", "paymentType")]], ["method", "finalAmount", [(0, _decorators.default)("formatedPrice", "swapdFee", "transferFee", "category")]], ["method", "exceedsLimitOA", [(0, _decorators.default)("orgAmount", "formatedPrice", "category", "membershipType", "paymentType")]], ["method", "orgAmount", [(0, _decorators.default)("formatedPrice", "category", "membershipType", "paymentType")]]]));
});